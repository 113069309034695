export async function getIceServers() {
  try {
    const response = await fetch("https://global.xirsys.net/_turn/MyFirstApp", {
      method: "PUT",
      headers: {
        Authorization: "Basic " + btoa("nvcmis:9f4e8b94-d7ec-11ef-94a4-0242ac130003"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ format: "urls" }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ICE servers: ${response.statusText}`);
    }
    const data = await response.json();
    console.log("Full Xirsys Response:", data);
    // ✅ Ensure `data.v` exists and contains `iceServers`
    if (!response.ok || !data.v || !data.v.iceServers || !Array.isArray(data.v.iceServers.urls)) {
      console.warn("Invalid ICE servers format, using fallback STUN server.");
      return [{ urls: "stun:stun.l.google.com:19302" }];
    }

    // Convert the response format into an array of ICE server objects
    const iceServers = data.v.iceServers.urls.map((url) => ({
      urls: url,
      username: data.v.iceServers.username,
      credential: data.v.iceServers.credential,
    }));

    console.log("Processed ICE Servers:", iceServers);
    return iceServers;
  } catch (error) {
    console.error("Error fetching ICE servers:", error);
    return [{ urls: "stun:stun.l.google.com:19302" }]; // Fallback STUN server
  }
}
