import React, { useRef, useState, useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { getIceServers } from "../../_helpers/getIceServers";

const ReceiveVideoCall: React.FC = () => {
  const [connection, setConnection] = useState<any>(null);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const pc2 = useRef<RTCPeerConnection | null>(null);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_API_URL_FOR_CHAT + "userActivityHub")
      .build();
    connect
      .start()
      .then(() => console.log("Connected to signaling server"))
      .catch((error) => console.log("SignalR Connection Error:", error));

    setConnection(connect);

    async function initPeerConnection() {
      const iceServers = await getIceServers(); // Fetch TURN/STUN servers dynamically
      pc2.current = new RTCPeerConnection({ iceServers });

      // Handle ICE candidates
      pc2.current.onicecandidate = (event) => {
        if (event.candidate && connection) {
          console.log("Sending ICE candidate:", event.candidate);
          connection.invoke("SendIceCandidate", JSON.stringify(event.candidate));
        }
      };

      // ✅ Handle received ICE candidates
      connect.on("ReceiveIceCandidate", async (candidate: string) => {
        try {
          const myCandidate: RTCIceCandidateInit = JSON.parse(candidate);
          await pc2.current?.addIceCandidate(myCandidate);
          console.log("Added ICE candidate:", myCandidate);
        } catch (error) {
          console.error("Error adding ICE candidate:", error);
        }
      });

      // ✅ Handle Offer from sender
      connect.on("ReceiveOffer", async (offer: string) => {
        try {
          const myOffer = JSON.parse(offer);
          console.log("Received offer:", myOffer);

          await pc2.current?.setRemoteDescription(new RTCSessionDescription(myOffer));

          console.log("Remote description set. Creating answer...");
          if(pc2.current){
          const answer = await pc2.current.createAnswer();
          await pc2.current.setLocalDescription(answer);

          console.log("Sending answer:", answer);
          connect.invoke("SendAnswer", JSON.stringify(answer));
          }
        } catch (error) {
          console.error("Error processing received offer:", error);
        }
      });

      // ✅ Handle receiving media tracks
      pc2.current.ontrack = (event) => {
        console.log("Remote track received:", event.streams[0]);
      
        if (!remoteVideoRef.current) {
          console.error("Remote video element is null.");
          return;
        }
      
        const remoteStream = event.streams[0];
      
        // ✅ Prevent reassigning the same stream to avoid interruptions
        if (remoteVideoRef.current.srcObject !== remoteStream) {
          remoteVideoRef.current.srcObject = remoteStream;
          console.log("Remote video srcObject set:", remoteStream);
          remoteVideoRef.current
            .play()
            .then(() => console.log("Remote video playback started."))
            .catch((err) => console.error("Error forcing video playback:", err));
        }
     };
      console.log("PeerConnection initialized.");
    }
    initPeerConnection();
  }, []);

  return (
    <div>
      <h3>Receiving Video Call</h3>
      <video ref={remoteVideoRef} playsInline autoPlay muted={false} controls
      style={{ width: "100%", height: "70vh" }} />
    </div>
  );
};

export default ReceiveVideoCall;
