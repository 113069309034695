import React, {useState, useEffect} from "react";
import LoginHeader from "../../components/Header/loginHeader";
import LoginContent from "../../components/loginContent";
import Main from '../../components/lecturer';
import Admin from '../../components/admin';
import InternLayout from '../guessLogined/internLayout';
import StudentLayout from '../students/studentLayout';
import EnterpriseLayout from '../enterprise';
import ChangePassLayout from '../changepass';
import {getAdmins} from '../../components/admin/adminSlide';
import {getAllUniversities} from '../../layouts/registers/universitySlice';
import { useAppDispatch } from '../../configs/hook';
import { message } from "antd";
const getMessage = localStorage.getItem("message");
const LoginLayout = ()=>{
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');
    const [updated, setUpdated] = useState(false);
    const getEmail = localStorage.getItem("emailData");
  const getRole = localStorage.getItem("roleData");
  const getMessage = localStorage.getItem("message");
  const getEnableRegister = localStorage.getItem("enableRegister");
  const getPasswordStatus = localStorage.getItem("passwordStatus");
    const handleRerenderApp = () => {
        setUpdated(!updated);
      }
      const myComponent = ()=>{
        if(getPasswordStatus == 'true'){
          switch(getRole)
          {
            case "1":
              return <StudentLayout />
            case "2":
              return <Main />
            case "3":
              dispatch(getAdmins());
              return <Admin />
            case "4":
              dispatch(getAllUniversities());
              return <InternLayout />
            case "6":
              dispatch(getAllUniversities());
              return <InternLayout />
            case "5":
                return <EnterpriseLayout />
            default:
              return <>
                    <LoginHeader />
                    <LoginContent message={getMessage?getMessage:""} reRenderApp={()=>handleRerenderApp()} />
              </>
          }
        }
        else{
          if(getPasswordStatus == null)
            return <>
                <LoginHeader />
                <LoginContent message={getMessage?getMessage:""} reRenderApp={()=>handleRerenderApp()} />
            </>
          else
            return <ChangePassLayout />
        }  
      }
    return(
        <>
            {myComponent()};
        </>
    )
}
export default LoginLayout;