import React, { useRef, useState, useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { getIceServers } from "../../_helpers/getIceServers";

const VideoCall: React.FC = () => {
  const [connection, setConnection] = useState<any>(null);
  const localVideoRef = useRef<HTMLVideoElement | null>(null);
  const localStreamRef = useRef<MediaStream | null>(null);
  const pc1 = useRef<RTCPeerConnection | null>(null);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_API_URL_FOR_CHAT + "userActivityHub")
      .build();

    connect
      .start()
      .then(() => console.log("Connected to signaling server"))
      .catch((error) => console.log("SignalR Connection Error:", error));

    setConnection(connect);

    async function initPeerConnection() {
      const iceServers = await getIceServers(); // Fetch TURN/STUN servers dynamically
      pc1.current = new RTCPeerConnection({ iceServers });

      pc1.current.onicecandidate = (event) => {
        if (event.candidate && connection) {
          console.log("Sending ICE candidate:", event.candidate);
          connection.invoke("SendIceCandidate", JSON.stringify(event.candidate));
        }
      };

      connect.on("ReceiveIceCandidate", async (candidate: string) => {
        try {
          const myCandidate: RTCIceCandidateInit = JSON.parse(candidate);
          await pc1.current?.addIceCandidate(myCandidate);
          console.log("Added remote ICE candidate:", myCandidate);
        } catch (error) {
          console.error("Error adding ICE candidate:", error);
        }
      });

      await attachLocalMedia();
    }
    initPeerConnection();
  }, []);

  const attachLocalMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 1280, height: 720 },
        audio: true,
      });

      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
        localStreamRef.current = stream;
      }

      // ✅ Fix: Add local stream tracks to PeerConnection
      stream.getTracks().forEach((track) => {
        pc1.current?.addTrack(track, stream);
        console.log("Added track:", track);
      });

      console.log("Local media attached.");
    } catch (error) {
      console.error("Error accessing local media:", error);
    }
  };

  const peerConnection = async () => {
    if (!pc1.current || !connection) {
      console.error("PeerConnection or SignalR connection not ready.");
      return;
    }

    try {
      if (!localStreamRef.current) {
        console.error("No local stream available.");
        return;
      }
      const offer = await pc1.current.createOffer();
      await pc1.current.setLocalDescription(offer);
      console.log("Offer created and set:", offer);
      // ✅ Fix: Ensure connection is established before invoking
      if (connection.connectionId) {
        connection.invoke("SendOffer", JSON.stringify(offer));
      } else {
        console.error("SignalR connection not ready for sending offer.");
      }
    } catch (error) {
      console.error("Error during peer connection setup:", error);
    }
  };
  return (
    <div>
      <button onClick={peerConnection}>Live Stream</button>
      <video ref={localVideoRef} playsInline autoPlay muted={false} controls
      style={{ width: "100%", height: "70vh" }} />
    </div>
  );
};
export default VideoCall;
